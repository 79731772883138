import Editor, { EditorOptions } from "@toast-ui/editor";
import { v4 as uuidv4 } from 'uuid';

export interface RichTextEditorPlugin<TOptions = any> {
    plugin: (staticEditor: typeof Editor, options?: TOptions) => (editor: Editor) => void;
    options?: TOptions;
}

const defaultToolbarItems = [
    "heading",
    "bold",
    "italic",
    "strike",
    "divider",
    "ul",
    "ol",
    "task",
    "indent",
    "outdent",
    "hr",
    "qoute",
    "table",
    "image",
    "link",
    "code",
    "codeBlock"
] as const;

type DefaultToolbarItem = typeof defaultToolbarItems[number];

export interface IRichTextEditorOptions {

    /**
     * Selector or HTML element for the text area you want to initialize
     */
    textArea: string | HTMLElement;

    /**
     * Optional: The height of rich text editor. If not provided, the height will be auto.
     */
    height?: string;

    /**
     * Optional: The min height of rich text editor. If not provided, the height will be 550px.
     */
    minHeight?: string;

    /**
     * Optional: The language of the editor. If not provided, the default language will be danish.
     */
    languageSettings?: ILanguageSettings;

    /**
     * Optional: Which toolbar items to show. If not provided, the default toolbar items will be shown.
     */
    toolbarItems?: IToolbarItems;

    /**
     * Optional: The ability to switch editor mode ("wysiwyg" and "markdown"). If not provided, the button to switch between modes will not be shown.
     */
    showModeSwitch?: boolean;

    /**
     * Optional: The editors mode. If not provided, the default editor mode will be "wysiwyg".
     */
    editorMode?: "wysiwyg" | "markdown";

    /**
     * Optional: Customplugin for the editor if needed.
     */
    customPlugins?: RichTextEditorPlugin[];

    /**
     * Optional: Override additional settings. Does not override settings defined in IRichTextEditorOptions.
     */
    additionalRichTextEditorSettings?: EditorOptions;

    /**
     * Optional: Prevent enter event and shift+enter event in WYSIWYG editor .
     */
    preventNewLine?: boolean


    /**
     * Optional: On change callback.
     */
    onEditorChanged?: () => void;

}

export interface IToolbarItems {

    /**
     * Optional: Override with custom toolbar, supplying the buttons and their order. Takes a list of strings which can be the buttons included in the rich text editor, or buttons of any provided plugins. If not provided default settings will apply.
     * The included button options are: "heading", "bold", "italic", "strike", "divider", "ul", "ol", "task", "indent", "outdent", "hr", "qoute", "table", "image", "link", "code", "codeBlock".
     */
    customToolbar?: ( DefaultToolbarItem | string) [];

    /**
     * Optional: Bold text style. If not provided, the style button will be available.
     */
    showBold?: boolean;

    /**
     * Optional: Provide headings for the module.  If not provided, the style button will be available.
     */
    showHeadings?: boolean;

    /**
     * Optional: Italic text style. If not provided, the style button will be available.
     */
    showItalic?: boolean;

    /**
     * Optional: Strike text style. If not provided, the style button will be available.
     */
    showStrike?: boolean;

    /**
     * Optional: A button for creating a horizontal line. If not provided, button will not be available.
     */
    showHr?: boolean;

    /**
     * Optional: A button for creating a blockqoute. If not provided, the button will not be available.
     */
    showBlockQuote?: boolean;

    /**
     * Optional: Button for creating unordered lists. If not provided, the list button will be available.
     */
    showULListOption?: boolean;

    /**
     * Optional: Button for creating ordered lists. If not provided, the list button will be available.
     */
    showOLListOption?: boolean;

    /**
     * Optional: Button for creating a task lists. If not provided, the list button will not be available.
     */
    showTaskListOption?: boolean;

    /**
     * Optional: Button for indenting a list. If not provided, the list button will be available.
     */
    showIndentListOption?: boolean;

    /**
     * Optional: Button for outdenting a lists. If not provided, the list button will be available.
     */
    showOutdentListOption?: boolean;

    /**
     * Optional: Button for creating a table. If not provided, the table button will not be available.
     */
    showTable?: boolean;

    /**
     * Optional: Button for creating a image. If not provided, the image button will not be available.
     */
    showImage?: boolean;

    /**
     * Optional: Button for creating a link. If not provided, the link button will not be available.
     */
    showLink?: boolean;

    /**
     * Optional: Buttons for creating a codeblock or inline code. If not provided, the buttons will not be available.
     */
    showCode?: boolean;
}

export interface ILanguageSettings {

    /**
     * The editors language. Use "custom" and the language dictionary to provide your own translations.
     */
    language: "en-GB" | "da-DK" | "custom";

    /**
     * Optional: Must be provided when using the "Custom" option for language. Every property must be translated if provided.
     */
    languageDictionary?: {
        /* eslint-disable @typescript-eslint/naming-convention */
        "Markdown": string,
        "WYSIWYG": string,
        "Write": string,
        "Preview": string,
        "Headings": string,
        "Paragraph": string,
        "Bold": string,
        "Italic": string,
        "Strike": string,
        "Code": string,
        "Line": string,
        "Blockquote": string,
        "Unordered list": string,
        "Ordered list": string,
        "Task": string,
        "Indent": string,
        "Outdent": string,
        "Insert link": string,
        "Insert CodeBlock": string,
        "Insert table": string,
        "Insert image": string,
        "Heading": string,
        "Image URL": string,
        "Select image file": string,
        "Description": string,
        "OK": string,
        "More": string,
        "Cancel": string,
        "File": string,
        "URL": string,
        "Link text": string,
        "Add row": string,
        "Add col": string,
        "Remove row": string,
        "Remove col": string,
        "Align left": string,
        "Align center": string,
        "Align right": string,
        "Remove table": string,
        "Would you like to paste as table?": string,
        "Text color": string,
        "Auto scroll enabled": string,
        "Auto scroll disabled": string,
        "Choose language": string,
        /* eslint-enable @typescript-eslint/naming-convention */
    };
}

export class RichTextEditorModule {

    public editor: Editor;

    constructor(options: IRichTextEditorOptions) {
        this.initModule(options);
    }

    public getHtml(): string {
        return this.editor.getHtml();
    }

    public getMarkdown(): string {
        return this.editor.getMarkdown();
    }

    private initModule(options: IRichTextEditorOptions) {

        const $textarea = $(options.textArea as any);

        if ($textarea.length === 0) {
            // eslint-disable-next-line no-console
            console.log("Couldn't find textarea for rich text editor selector", options.textArea);
            return;
        }

        if ($textarea.length > 1) {
            // eslint-disable-next-line no-console
            console.warn("Rich text editor only supports one element for the selector", options.textArea);
            throw Error("Rich text editor only supports one element for the selector");
        }

        if($textarea.data("rich-text-editor-initialized") === true) {
            return;
        }

        $textarea.hide();
        const $editorDiv = $("<div>");

        // Default values
        let settings = this.generateDefaultSettings($textarea, options, $editorDiv);

        this.setLanguage(options);

        // Additional settings
        if (options.additionalRichTextEditorSettings !== undefined) {
            settings = $.extend(true, {}, settings, options.additionalRichTextEditorSettings);
        }

        const editor = new Editor(settings);

        if(options.preventNewLine) {
            const currentEditor = editor.getCurrentModeEditor() as toastui.WysiwygEditor;

            if(currentEditor.addKeyEventHandler !== undefined && currentEditor.addKeyEventHandler !== null) {
                currentEditor.addKeyEventHandler(["ENTER", "SHIFT+ENTER"], (event: KeyboardEvent) => {
                    event.preventDefault();
                    return false;
                });
            }
        }

        const uuid = uuidv4();
        $editorDiv.find(".tui-editor-contents").attr("name", "rich-text-editor-" + uuid);

        // Update text area
        editor.on("change", () => {
            $textarea.text(editor.getMarkdown());

            if(options.onEditorChanged !== undefined && options.onEditorChanged !== null)
            {
                options.onEditorChanged();
            }
        });

        this.editor = editor;

        $textarea.data("rich-text-editor-initialized", true);
    }

    private generateDefaultSettings($textarea: JQuery<HTMLElement>, options: IRichTextEditorOptions, $editorDiv: JQuery<HTMLElement>) {

        $textarea.before($editorDiv);

        const settings = {
            el: $editorDiv[0],
            height: "auto",
            initialEditType: "wysiwyg",
            initialValue: $textarea.val(),
            hideModeSwitch: true,
            usageStatistics: false,
            minHeight: "550px",
            language: "da-DK"
        } as EditorOptions;

        if (options.height !== undefined && options.height !== null) {
            settings.height = options.height;
        }

        if (options.customPlugins !== undefined && options.customPlugins !== null) {
            const plugins = options.customPlugins.map((customPlugin) => customPlugin.plugin(Editor, customPlugin.options));

            settings.plugins = plugins;
        }

        if (options.minHeight !== undefined && options.minHeight !== null) {
            settings.minHeight = options.minHeight;
        }

        if (options.editorMode !== undefined && options.editorMode !== null) {
            settings.initialEditType = options.editorMode;
        }

        if (options.showModeSwitch === true) {
            settings.hideModeSwitch = false;
        }

        if(options.languageSettings !== undefined && options.languageSettings !== null) {
            settings.language = options.languageSettings.language;
        }

        settings.toolbarItems = this.generateToolbarFromOptions(options);

        return settings;
    }

    private setLanguage(options: IRichTextEditorOptions) {

        if (options.languageSettings === undefined || options.languageSettings === null || options.languageSettings.language === "da-DK") {
            Editor.setLanguage("da-DK", {
                /* eslint-disable @typescript-eslint/naming-convention */
                "Markdown": "Markdown",
                "WYSIWYG": "WYSIWYG",
                "Write": "Skriv",
                "Preview": "Forhåndsvisning",
                "Headings": "Overskrifter",
                "Paragraph": "Afsnit",
                "Bold": "Fed",
                "Italic": "Kursiv",
                "Strike": "Gennemstreget",
                "Code": "Kode",
                "Line": "Linje",
                "Blockquote": "Blockquote",
                "Unordered list": "Uordnet liste",
                "Ordered list": "Ordnet liste",
                "Task": "Opgave",
                "Indent": "Forminsk indrykning",
                "Outdent": "Forøg indrykning",
                "Insert link": "Indsæt link",
                "Insert CodeBlock": "Indsæt kodeblock",
                "Insert table": "Indsæt tabel",
                "Insert image": "Indsæt billede",
                "Heading": "Overskrift",
                "Image URL": "Billede URL",
                "Select image file": "Vælg billede",
                "Description": "Beskrivelse",
                "OK": "OK",
                "More": "Mere",
                "Cancel": "Annuller",
                "File": "Fil",
                "URL": "URL",
                "Link text": "Link tekst",
                "Add row": "Tilføj række",
                "Add col": "Tilføj kolonne",
                "Remove row": "Fjern række",
                "Remove col": "Fjern kolonne",
                "Align left": "Venstrejuster",
                "Align center": "Centreret",
                "Align right": "Højrejuster",
                "Remove table": "Fjern tabel",
                "Would you like to paste as table?": "Vil du gerne indsætte som tabel?",
                "Text color": "Tekstfarve",
                "Auto scroll enabled": "Autoscroll aktiveret",
                "Auto scroll disabled": "Autoscroll deaktiveret",
                "Choose language": "Vælg sprog"
                /* eslint-enable @typescript-eslint/naming-convention */
            });
        } else if (options.languageSettings.language === "custom") {
            Editor.setLanguage(options.languageSettings.language, options.languageSettings.languageDictionary);
        }
    }

    private generateToolbarFromOptions(options: IRichTextEditorOptions): string[] {

        const toolbarItems: any[] = [];

        if (options.toolbarItems === undefined) {
            return [
                "heading",
                "bold",
                "italic",
                "strike",
                "divider",
                "ul",
                "ol",
                "task",
                "indent",
                "outdent",
            ];
        }

        if(options.toolbarItems.customToolbar !== undefined && options.toolbarItems.customToolbar !== null) {

            options.toolbarItems.customToolbar.forEach((item) => {
                if(this.isToolbarItemCustom(item)) {
                    toolbarItems.push({
                        type: "item",
                        options: {
                            name: item
                        }
                    });
                }
                else {
                    toolbarItems.push(item);
                }
            });

            return toolbarItems;
        }

        if (options.toolbarItems.showBold !== false) {
            toolbarItems.push("bold");
        }

        if (options.toolbarItems.showHeadings !== false) {
            toolbarItems.push("heading");
        }

        if (options.toolbarItems.showItalic !== false) {
            toolbarItems.push("italic");
        }

        if (options.toolbarItems.showStrike !== false) {
            toolbarItems.push("strike");
        }

        const lastToolbarItem = toolbarItems[toolbarItems.length-1];

        if(lastToolbarItem !== "divider") {
            toolbarItems.push("divider");
        }

        const shouldShowHr = options.toolbarItems.showHr === true;
        const shouldShowBlockQuote = options.toolbarItems.showBlockQuote === true;

        if (shouldShowHr) {
            toolbarItems.push("hr");
        }

        if (shouldShowBlockQuote) {
            toolbarItems.push("quote");
        }

        this.insertDividerinToolbarIfLastItemIsNotDivider(toolbarItems);

        if (options.toolbarItems.showULListOption !== false) {
            toolbarItems.push("ul");
        }

        if (options.toolbarItems.showOLListOption !== false) {
            toolbarItems.push("ol");
        }

        if (options.toolbarItems.showTaskListOption === true) {
            toolbarItems.push("task");
        }

        if (options.toolbarItems.showIndentListOption !== false) {
            toolbarItems.push("indent");
        }

        if (options.toolbarItems.showOutdentListOption !== false) {
            toolbarItems.push("outdent");
        }

        this.insertDividerinToolbarIfLastItemIsNotDivider(toolbarItems);

        const shouldShowLink = options.toolbarItems.showLink === true;
        const shouldShowTable = options.toolbarItems.showTable === true;
        const shouldShowImage = options.toolbarItems.showImage === true;

        if (shouldShowTable) {
            toolbarItems.push("table");
        }

        if (shouldShowImage) {
            toolbarItems.push("image");
        }

        if (shouldShowLink) {
            toolbarItems.push("link");
        }

        this.insertDividerinToolbarIfLastItemIsNotDivider(toolbarItems);

        if (options.toolbarItems.showCode === true) {
            toolbarItems.push("code");
            toolbarItems.push("codeblock");
        }

        return toolbarItems;
    }

    private insertDividerinToolbarIfLastItemIsNotDivider(toolbarItems: string[]) {
        const lastToolbarItem = toolbarItems[toolbarItems.length - 1];

        if (lastToolbarItem !== "divider") {
            toolbarItems.push("divider");
        }
    }

    private isToolbarItemCustom(item: string): boolean {

        if(defaultToolbarItems.includes(item as any)) {
            return false;
        }

        return true;
    }
}
