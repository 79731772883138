interface IInputStatusModuleSettings {

    /**
     * Position of the icon, when used on a div or span.
     */
    positionInDiv?: "top-center" | "center-right" | "top-right";
}

export default class InputStatusModule {

    private transisionEndEvent = "transitionend MSTransitionEnd webkitTransitionEnd oTransitionEnd";
    private $originalElement: JQuery;
    private $elementToCover: JQuery;

    private $iconElement: JQuery;
    private $iconWrapperElement: JQuery;

    private originalHtml: string;

    private settings: IInputStatusModuleSettings;

    constructor($element: JQuery, settings?: IInputStatusModuleSettings) {

        if ($element.length === 0) {
            throw Error("InputStatusModule cannot find any elements matching the given jquery element");
        }

        if ($element.length > 1) {
            throw Error("InputStatusModule found " + $element.length + " jquery elements. Only 1 is allowed.");
        }

        this.$originalElement = $element;
        this.settings = settings;

        this.createIconElement(this.$originalElement);
    }

    public showLoading(): void {

        if(this.isInitialised() !== true)
            return;

        this.$iconElement.removeClass("loading success warning error");
        this.$iconElement.addClass("loading");
        this.show();

        if (this.$elementToCover !== undefined) {
            this.removeInputStatus();
        }
    }

    public showSuccess(): void {

        if(this.isInitialised() !== true)
            return;

        this.$iconElement.removeClass("loading success warning error");
        this.$iconElement.addClass("success");
        this.show();

        if (this.$elementToCover !== undefined) {
            this.setInputStatus("input-success");
        }
    }

    public showWarning(): void {

        if(this.isInitialised() !== true)
            return;

        this.$iconElement.removeClass("loading success warning error");
        this.$iconElement.addClass("warning");
        this.show();

        if (this.$elementToCover !== undefined) {
            this.setInputStatus("input-warning");
        }
    }

    public showError(): void {

        if(this.isInitialised() !== true)
            return;

        this.$iconElement.removeClass("loading success warning error");
        this.$iconElement.addClass("error");
        this.show();

        if (this.$elementToCover !== undefined) {
            this.setInputStatus("input-danger");
        }
    }

    public show(): void {

        if(this.isInitialised() !== true)
            return;

        this.$iconElement.addClass("in");

        if (this.elementIsLinkOrButton(this.$originalElement)) {
            this.$originalElement.text("");
            this.$originalElement.append(this.$iconWrapperElement);
        }

        if (this.elementIsTextInputWithInputGroup(this.$originalElement)) {
            const $inputGroupText = this.$originalElement.next(".input-group-append").find(".input-group-text");

            $inputGroupText.html("");
            $inputGroupText.append(this.$iconWrapperElement);
            this.setElementEmptyState($inputGroupText);
        }
    }

    public hide(): void {

        if(this.isInitialised() !== true)
            return;

        this.$iconElement.removeClass("in");
        this.removeInputStatus();

        if (this.elementIsLinkOrButton(this.$originalElement)) {
            this.$iconElement.on(this.transisionEndEvent,
                () => {
                    if(this.$originalElement !== undefined)
                        this.$originalElement.html(this.originalHtml);
                });
        }

        if (this.elementIsTextInputWithInputGroup(this.$originalElement)) {
            this.$iconElement.on(this.transisionEndEvent,
                () => {
                    if(this.$originalElement !== undefined){
                        const $inputGroupText = this.$originalElement.next(".input-group-append").find(".input-group-text");
                        $inputGroupText.html(this.originalHtml);
                        this.setElementEmptyState($inputGroupText);
                    }
                });
        }

        // Manually trigger animationend if animations are disabled on OS
        const animationsDisabled = window.matchMedia("prefers-reduced-motion").matches;
        if (animationsDisabled === true) {
            this.$iconElement.trigger("transitionend");
        }
    }

    public removeWithFadeOut(doneCallback?: () => void): void {

        if(this.isInitialised() !== true)
            return;

        const removeElement = () => {
            this.remove();
            if (doneCallback !== undefined) {
                doneCallback();
            }
        };

        if(this.isVisible()) {
            this.$iconElement.on(this.transisionEndEvent,
                () => {
                    removeElement();
                });

            this.hide();
        }
        else {
            removeElement();
        }
    }

    public isVisible(): boolean {

        if(this.isInitialised() !== true)
            return false;

        return this.$iconElement.hasClass("in");
    }

    public remove(): void {

        if(this.isInitialised() !== true)
            return;

        this.hide();

        this.$iconElement.remove();
        this.$iconElement = undefined;

        if (this.$iconWrapperElement !== undefined) {
            this.$iconWrapperElement.remove();
            this.$iconWrapperElement = undefined;
        }

        if (this.elementIsLinkOrButton(this.$originalElement)) {
            this.$originalElement.html(this.originalHtml);
        }

        if(this.elementIsTextInputWithInputGroup(this.$originalElement)) {
            const $inputGroupText = this.$originalElement.next(".input-group-append").find(".input-group-text");
            $inputGroupText.html(this.originalHtml);
            this.setElementEmptyState($inputGroupText);
        }

        this.$originalElement = undefined;
    }

    public isInitialised(): boolean {
        return this.$iconElement !== undefined;
    }

    private removeInputStatus(): void {
        if (this.$elementToCover !== undefined) {
            const classesToRemove = "input-success input-warning input-danger";
            this.$elementToCover.removeClass(classesToRemove);

            if(this.elementIsSelect2(this.$elementToCover)) {
                this.$elementToCover.find(".select2-selection").removeClass(classesToRemove);
            }
        }
    }

    private setInputStatus(inputStatus: string): void {
        this.removeInputStatus();

        if (this.$elementToCover !== undefined) {
            this.$elementToCover.addClass(inputStatus);

            if(this.elementIsSelect2(this.$elementToCover)) {
                this.$elementToCover.find(".select2-selection").addClass(inputStatus);
            }
        }
    }

    private createIconElement($element: JQuery): void {

        if (this.elementIsLinkOrButton($element)) {
            this.createIconElementForButton($element);
        } else if (this.elementIsDivOrParagraph($element)) {
            this.createIconElementForDiv($element);
        } else if (this.elementIsTextInputWithInputGroup($element)) {
            this.createIconElementForTextInputWithInputGroup($element);
        } else {
            this.createIconElementForInput($element);
        }
    }

    private createIconElementForButton($element: JQuery): void {

        const buttonWidth = $element.outerWidth();
        const buttonHeight = $element.outerHeight();

        this.originalHtml = $element.html();

        this.$iconWrapperElement = $("<div>", {class: "element-status-icon-wrapper"});
        this.$iconElement = $("<div>", {class: "element-status-icon element-type-button"});
        this.$iconWrapperElement.append(this.$iconElement);

        $element.css("width", buttonWidth);
        $element.css("height", buttonHeight);
    }

    private createIconElementForDiv($element: JQuery): void {

        $element.css("position", "relative");

        this.$iconElement = $("<div>", {class: "element-status-icon element-type-div"});

        let positionInDiv = "center-right";
        if (this.settings !== undefined && this.settings !== null && this.settings.positionInDiv !== undefined && this.settings.positionInDiv !== null && this.settings.positionInDiv.length > 0) {
            positionInDiv = this.settings.positionInDiv;
        }

        this.$iconElement.addClass("position-" + positionInDiv);

        $element.append(this.$iconElement);
    }

    private createIconElementForTextInputWithInputGroup($element: JQuery): void {
        this.$elementToCover = $element;

        this.$iconWrapperElement = $("<div>", {class: "element-status-icon-wrapper"});
        this.$iconElement = $("<div>", {class: "element-status-icon element-type-input"});
        this.$iconWrapperElement.append(this.$iconElement);

        const $inputGroup = $element.parent(".input-group");
        const $inputGroupAppend = $inputGroup.find(".input-group-append");

        let $inputGroupText = $inputGroupAppend.find(".input-group-text");
        if($inputGroupText.length === 0){
            $inputGroupText = $("<div>", {class: "input-group-text"});
            $inputGroupAppend.append($inputGroupText);
        }

        this.originalHtml = $inputGroupText.html();
    }

    private createIconElementForInput($element: JQuery): void {
        this.$elementToCover = $element;

        const isSelect2 = this.elementIsSelect2($element);
        if (isSelect2 === true) {
            this.$elementToCover = $element.next("span.select2");
        }

        const offsetPosition = this.$elementToCover.position();

        this.$iconElement = $("<div>", {
            class: "element-status-icon element-type-input",
            css: {
                "position": "absolute",
                "top": offsetPosition.top
            }
        });

        const isCheckbox = $element.prop("type") === "checkbox";
        if (isCheckbox === true) {
            this.$iconElement.addClass("updating-checkbox");
        } else {

            const iconWidth = 36;
            const width = this.$elementToCover.outerWidth();

            let left = offsetPosition.left + width - iconWidth;

            if (isSelect2 === true) {

                if(this.$elementToCover.find(".select2-selection--single .select2-selection__clear").length > 0) {
                    left = left - 32;
                }
                else {
                    left = left - 16;
                }
            }

            this.$iconElement.css("left", left);
            this.$iconElement.css("width", iconWidth);
            this.$iconElement.css("height", this.$elementToCover.outerHeight());
        }

        this.$iconElement.on("click", () => {

            if (isSelect2 === true) {
                $element.select2("open");
            } else {
                $element.trigger("focus");
            }

        });

        const isSwitch = this.$elementToCover.hasClass("switch");
        if (isSwitch === true) {
            this.$iconElement.addClass("updating-switch");
            this.$elementToCover.parent(".switch-container").prepend(this.$iconElement);
        } else if (isCheckbox === true) {
            this.$elementToCover.before(this.$iconElement);
        } else {
            this.$elementToCover.after(this.$iconElement);
        }
    }

    private elementIsLinkOrButton($element: JQuery): boolean {
        const nodeName = $element.prop("nodeName");
        return nodeName === "BUTTON" || nodeName === "A";
    }

    private elementIsDivOrParagraph($element: JQuery): boolean {
        const nodeName = $element.prop("nodeName");
        return nodeName === "DIV" || nodeName === "SPAN" || nodeName === "P";
    }

    private elementIsTextInputWithInputGroup($element: JQuery): boolean {
        const $inputGroup = $element.parent(".input-group");
        const $inputGroupAppend = $element.next(".input-group-append");

        return $inputGroup.length > 0 && $inputGroupAppend.length > 0;
    }

    private elementIsSelect2($element: JQuery): boolean {
        return $element.hasClass("select2-hidden-accessible") || $element.hasClass("select2");
    }

    private setElementEmptyState($element: JQuery) {
        if(!$.trim($element.html()).length) {
            $element.addClass("no-content");
        }
        else {
            $element.removeClass("no-content");
        }
    }
}
