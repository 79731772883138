export default class TooltipModule {

    constructor($element: JQuery) {
        $element.each(function() {
            const className = $(this).data("tooltip");
            $(this).tooltip({
                template : "<div class=\"tooltip tooltip--" + className + "\" role=\"tooltip\"><div class=\"arrow\"></div><div class=\"tooltip-inner\"></div></div>",
            });
        });
    }
}
