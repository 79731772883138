import dayjs from "dayjs";
import customFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customFormat);

$.fn.dataTable.dayjs = (format, locale) => {

    const types = $.fn.dataTable.ext.type;

    // Add type detection
    types.detect.unshift((d: string) => {
        if (d) {
            // Strip HTML tags and newline characters if possible
            if (d.replace) {
                d = d.replace(/(<.*?>)|(\r?\n|\r)/g, "");
            }

            // Strip out surrounding white space
            d = $.trim(d);
        }

        // Null and empty values are acceptable
        if (d === "" || d === null) {
            return "dayjs-" + format;
        }

        return dayjs(d, format, locale, true).isValid() ?
            "dayjs-" + format :
            null;
    });

    // Add sorting method - use an integer for the sorting
    types.order["dayjs-" + format + "-pre"] = (d: string ) => {
        if (d) {
            // Strip HTML tags and newline characters if possible
            if (d.replace) {
                d = d.replace(/(<.*?>)|(\r?\n|\r)/g, "");
            }

            // Strip out surrounding white space
            d = $.trim(d);
        }

        return d === "" || d === null ?
            -Infinity :
            dayjs(d, format, locale, true).unix();
    };
};
