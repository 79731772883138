import { NotificationModule } from "../notification";
import InputStatusModule from "../inputStatus";

export type AsyncValueUpdateData = {
    id?: number,
    value: any
};

export interface AsyncValueUpdateOptions {

     /**
     * Optional: If you wish to set the id value on the data supplied to the API end point.
     */
    getIdCallback?: ($element: JQuery) => number;

    /**
     * Optional: If you wish to perform a form validation before submitting. If not supplied the module wont perform form validation.
     */
    performValidation?: boolean;

    /**
     * Optional: Callback if the update request is failed
     */
    updateFailedCallback?: ($element: JQuery, jqXHR: JQuery.jqXHR<any>, errorTextStatus: JQuery.Ajax.ErrorTextStatus, errorThrown: string) => void;

    /**
     * Optional: Callback if when the request is finished
     */
    updateCompletedCallback?: ($element: JQuery, successData: any, successTextStatus: JQuery.Ajax.SuccessTextStatus, jqXHR: JQuery.jqXHR<any>) => void;

    /**
     * Optional: Callback to supply the API endpoint with more data, if needed.
     */
    getAdditionalPostParametersCallback?: ($element: JQuery) => object;
}


/** This module is used for updating input fields asynchronous.
     *  The elements with the given selector must have a data attribute called "async-value-update-url" in order to work.
     *  The value of async-value-update-url is an endpoint of your API with a parameter called "Id" and "Value".
     *  You will also need a form with the a class called fake-async-validation-form if you wish the validation to work properly.
     */
export default class AsyncValueUpdateModule {

    init(selector: string, options?: AsyncValueUpdateOptions): void {

        //Set performValidation default to true
        if (options.performValidation !== false)
            options.performValidation = true;

        this.initAsyncValueUpdateModule(selector, options);
    }


    private initAsyncValueUpdateModule(selector: string, options?: AsyncValueUpdateOptions) {

        const self = this;

        $(selector).each((index, element) => {

            const $element = $(element);

            if ($element.data("async-value-update-module-initialized") === true)
                return;

            const updateUrl = $element.data("async-value-update-url");
            if (updateUrl === undefined || updateUrl.length <= 0) {
                console.log("Invalid updateUrl for element", $element, updateUrl);
                return;
            }

            let elementName = $element.data("async-value-update-name");
            if (elementName === undefined || elementName.length <= 0) {
                const $previousElement = $element.prev();

                if ($previousElement !== undefined && $previousElement.prop("nodeName") === "LABEL") {
                    elementName = $previousElement.html();
                }
            }

            const revertChangeOnError = $element.data("async-value-update-revert-on-error") != false;

            let changeEventName = $element.data("async-value-update-event");
            if(changeEventName === undefined || changeEventName.length === 0)
                changeEventName = "change";


            let showSuccessNotification = $element.data("async-value-update-show-success-notification");
            if(showSuccessNotification === undefined)
                showSuccessNotification = true;

            let inputStatusModule: InputStatusModule;
            let timeoutHandle: number;

            let oldValue = self.getValueFromElement($element);

            $element.on(changeEventName, () => {

                if (timeoutHandle !== undefined)
                    window.clearTimeout(timeoutHandle);

                if (inputStatusModule === undefined || inputStatusModule.isInitialised() === false)
                    inputStatusModule = new InputStatusModule($element);

                const asyncValidationForm = $element.closest("form.fake-async-validation-form");
                const elementId = $element.prop("id");

                if (options.performValidation === true) {
                    const validateElementResult = asyncValidationForm.validate().element("#" + elementId);
                    if (validateElementResult === false) {
                        inputStatusModule.showError();
                        return;
                    }
                }


                const value = self.getValueFromElement($element);

                const asyncValueUpdateData: AsyncValueUpdateData = {
                    value: value
                };

                if (options !== undefined && options.getIdCallback !== undefined) {
                    asyncValueUpdateData.id = options.getIdCallback($element);
                }

                let postData: any;
                if(options !== undefined && options.getAdditionalPostParametersCallback !== undefined) {
                    const extraParameters = options.getAdditionalPostParametersCallback($element);
                    postData = {
                        ...asyncValueUpdateData,
                        ...extraParameters
                    };
                }
                else {
                    postData = asyncValueUpdateData;
                }

                $element.prop("disabled", "");
                inputStatusModule.showLoading();

                $.ajax({
                    method: "POST",
                    url: updateUrl,
                    data: postData
                })
                    .fail((jqXHR, textStatus, errorThrown) => {
                        inputStatusModule.showError();

                        if(options.updateFailedCallback !== undefined) {
                            options.updateFailedCallback($element, jqXHR, textStatus, errorThrown);
                        }

                        if(revertChangeOnError === true)
                            self.setValueForElement($element, oldValue);
                    })
                    .done((successData, textStatus, jqXHR) => {
                        if (showSuccessNotification === true && elementName !== undefined)
                            NotificationModule.showSuccessSmall(elementName + " blev succesfuldt opdateret");

                        if ($element.prop("type") !== "checkbox")
                            inputStatusModule.showSuccess();

                        if(options.updateCompletedCallback !== undefined) {
                            options.updateCompletedCallback($element, successData, textStatus, jqXHR);
                        }

                        oldValue = value;
                    })
                    .always(() => {
                        $element.prop("disabled", "");

                        if ($element.prop("type") === "checkbox")
                            inputStatusModule.remove();
                        else {
                            timeoutHandle = window.setTimeout(() => {
                                inputStatusModule.removeWithFadeOut();
                            }, 4000);
                        }
                    });
            });

            $element.data("async-value-update-module-initialized", true);
        });
    }

    private getValueFromElement($element: JQuery) {
        const elementType = $element.prop("nodeName");

        switch (elementType) {

            case "INPUT":
                return this.getValueFromInputElement($element);

            case "SELECT":
                return $element.val();

            case "TEXTAREA":
                return $element.val();

            default:
                console.log("Error getting value from element", elementType, $element);
                throw Error("Error getting value from element type " + elementType);
        }
    }

    private setValueForElement($element: JQuery, value: any): void {
        const elementType = $element.prop("nodeName");

        switch (elementType) {

            case "INPUT":
                this.setValueForInputElement($element, value);
                break;

            case "SELECT":
            case "TEXTAREA":
                $element.val(value);
                break;

            default:
                console.log("Error getting value from element", elementType, $element);
                throw Error("Error getting value from element type " + elementType);
        }
    }


    private setValueForInputElement($element: JQuery, value: any): void {

        const inputType = $element.prop("type");

        switch (inputType) {

            case "text":
                $element.val(value);
                break;

            case "checkbox":
                if(value === true)
                    $element.prop("checked", true);
                else
                    $element.prop("checked", false);
                break;

            default:
                console.log("Error getting value from input element", inputType, $element);
                throw Error("Error getting value from element type " + inputType);
        }
    }

    private getValueFromInputElement($element: JQuery) {

        const inputType = $element.prop("type");

        switch (inputType) {

            case "text":
                return $element.val();

            case "checkbox":
                return $element.is(":checked");

            default:
                console.log("Error getting value from input element", inputType, $element);
                throw Error("Error getting value from element type " + inputType);
        }
    }
}
