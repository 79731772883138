"use strict";

export interface IClickableRowsModuleOptions {
    rowSelector?: string;
    linkRowAttribute?: string;
    excludeClass?: string;
}

export default class ClickableRowsModule {

    public init(options?: IClickableRowsModuleOptions) {
        this.initModule(options);
    }

    private initModule(options?: IClickableRowsModuleOptions) {

        let moduleOptions: IClickableRowsModuleOptions = {
            rowSelector: "table[data-clickable-rows] tr[data-clickable-row-url]",
            linkRowAttribute: "data-clickable-row-url",
        };

        if (options !== undefined) {
            moduleOptions = $.extend(true, moduleOptions, options);
        }

        $(moduleOptions.rowSelector).each(function(index, element) {

            const $element = $(element);

            if ($element.data("clickable-row-initialized") === true) {
                return;
            }

            $element.addClass("clickable-row");

            $element.on("mouseup", function(mouseClickEvent) {

                const url = $element.attr(moduleOptions.linkRowAttribute);

                if (mouseClickEvent.which <= 0 || mouseClickEvent.which > 2) {
                    return;
                }

                if (moduleOptions.excludeClass) {
                    const $target = $(mouseClickEvent.target);
                    const $searchForClosestInContext = $(mouseClickEvent.currentTarget)[0];
                    if ($target.hasClass(moduleOptions.excludeClass) || $target.closest("." + moduleOptions.excludeClass, $searchForClosestInContext).length > 0) {
                        return;
                    }
                }

                // Is ctrl click, cmd click or middle mouse
                if (mouseClickEvent.ctrlKey || mouseClickEvent.metaKey || mouseClickEvent.which === 2) {
                    window.open(url, "_blank");
                } else {
                    window.location.href = url;
                }
            });

            $element.data("clickable-row-initialized", true);
        });
    }
}
