import Cookies from "js-cookie";
import { CookieAttributes } from "js-cookie";

enum CookieConsentValues {
    accepted = "accepted",
    denied = "denied"
}

export default class CookieDisclaimerModule {

    private cookieConsentName = "cookieConsent";
    private $containerElement: JQuery<HTMLDivElement>;

    constructor(target: string, closeButtonSelector: string) {
        this.$containerElement = $(target);
        this.setupContainerElement();
        this.setupCloseButton(closeButtonSelector);
        this.initCookieConsentCheck();
    }

    public isConsentAccepted(): boolean {
        const cookieValue = Cookies.get(this.cookieConsentName);
        return cookieValue === CookieConsentValues.accepted;
    }

    private setupContainerElement() {
        this.$containerElement.addClass("cookie-disclaimer");
    }

    private setupCloseButton(closeButtonSelector: string): void {
        const $closeButton = $(closeButtonSelector);
        $closeButton.addClass("cookie-disclaimer-btn-close");
        $closeButton.on("click", () => {
            this.hideDisclaimer();
            this.saveConsent();
        });
    }

    private initCookieConsentCheck() {
        if (this.isConsentAccepted()) {
            this.hideDisclaimer();
        } else {
            this.showDisclaimer();
        }
    }

    private hideDisclaimer() {
        this.$containerElement.addClass("d-none");
    }

    private showDisclaimer() {
        this.$containerElement.removeClass("d-none");
    }

    private saveConsent() {
        const cookieAttributes: CookieAttributes = {
            path: "/",
            expires: 365,
            secure: true
        };

        Cookies.set(this.cookieConsentName, CookieConsentValues.accepted, cookieAttributes);
    }
}
