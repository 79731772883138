export interface ConfirmationModalModuleOptions {
    okCallback: (confirmationModalModule: ConfirmationModalModule) => void;
    cancelCallback?: (confirmationModalModule: ConfirmationModalModule) => void;
    didShowCallback?: (confirmationModalModule: ConfirmationModalModule) => void;

    title: string;
    body: string;

    showCancelButton?: boolean;

    okButtonText?: string;
    cancelButtonText?: string;
    deleteOnHide?: boolean;
    showCommentField?: boolean;
    commentFieldIsRequired?: boolean;

    actionIsRequired?: boolean;

    appendToSelector?: string;

    size?: number;
    modalType?: ConfirmationModalType;
}

export const enum ConfirmationModalType {
    SlideIn = 1,
    Original = 2,
    Message = 3,
}

export default class ConfirmationModalModule {
    public $modalDiv: JQuery;

    private options: ConfirmationModalModuleOptions;

    constructor(options: ConfirmationModalModuleOptions) {
        this.options = options;

        if (this.options.showCancelButton === undefined) {
            this.options.showCancelButton = true;
        }

        if (this.options.okButtonText === undefined) {
            this.options.okButtonText = "Ok";
        }

        if (this.options.cancelButtonText === undefined) {
            this.options.cancelButtonText = "Annuller";
        }

        if (this.options.deleteOnHide === undefined) {
            this.options.deleteOnHide = false;
        }

        if (this.options.showCommentField === undefined) {
            this.options.showCommentField = false;
        }

        if (this.options.commentFieldIsRequired === undefined) {
            this.options.commentFieldIsRequired = false;
        }

        if (this.options.appendToSelector === undefined) {
            this.options.appendToSelector = "body";
        }

        if (this.options.actionIsRequired === undefined) {
            this.options.actionIsRequired = false;
        }

        if (this.options.size === undefined) {
            this.options.size = 2;
        }

        if (this.options.modalType === undefined) {
            this.options.modalType = ConfirmationModalType.SlideIn;
        }

        this.initialiseConfirmationModal();
    }

    public show() {
        this.$modalDiv.modal("show");
    }

    public hide() {
        this.$modalDiv.modal("hide");
    }

    public getCommentValue() {
        const commentValue = $("#add-comment-textarea-confmodule").val();
        return commentValue;
    }

    public delete() {
        this.$modalDiv.data("bs.modal", null);
        this.$modalDiv.remove();
    }

    private initialiseConfirmationModal() {

        let modalBody = "" +
            "<div class=\"modal-body\">" + this.options.body + "</div>";
        
        let modalHeader = (
            "<div class=\"modal-header\">" +
                "<button type=\"button\" class=\"close\" data-dismiss=\"modal\"><span aria-hidden=\"true\">&times;</span></button>" +
                "<h4 class=\"modal-title\" id=\"myModalLabel\">" +
                    this.options.title +
                "</h4>" +
            "</div>");

        if (this.options.modalType === ConfirmationModalType.Message) {
            modalBody = (
                "<div class=\"modal-body\">" +
                    "<h3>" + this.options.title + "</h3>" +
                    "<p>" + this.options.body + "</p>" +
                "</div>"
            );

            modalHeader = "";
        } else if (this.options.showCommentField) {
            modalBody = (
                "<div class=\"modal-body\">" +
                    this.options.body +
                    "<br /><br />" +
                    '<form class="fake-comment-form">' +
                        '<div class="form-group" >' +
                            '<label for="add-comment-textarea-confmodule">Bemærkning</label>' +
                            '<textarea id="add-comment-textarea-confmodule" name= "add-comment-textarea-confmodule" class="form-control" placeholder= "Skriv en bemærkning ..." rows= "4" data-val="true" data-val-required="Der skal indtastes en bemærkning ..." ></textarea>' +
                            '<span class="field-validation-valid" data-valmsg-for="add-comment-textarea-confmodule" data-valmsg-replace="true"></span> ' +
                        "</div>" +
                    "</form>" +
                "</div>"
            );
        }

        let modalSizeClass = "";
        if (this.options.size === 1) {
            modalSizeClass = " modal-sm";
        } else if (this.options.size === 3) {
            modalSizeClass = " modal-lg";
        }

        let modalTypeClass = "";
        if (this.options.modalType === ConfirmationModalType.Original) {
            modalTypeClass = " modal-original";
        } else if (this.options.modalType === ConfirmationModalType.Message) {
            modalTypeClass = " modal-message";
        }

        let cancelButtonHtml = "";
        if (this.options.showCancelButton === true) {
            cancelButtonHtml = "" +
            "<button type=\"button\" class=\"btn btn-default cancel-button\">" +
                this.options.cancelButtonText +
            "</button>";
        }

        this.$modalDiv = $("" +
            "<div class=\"modal" + modalTypeClass + " fade\" tabindex=\"-1\" role=\"dialog\">" +
                "<div class=\"modal-dialog " + modalSizeClass + "\" role=\"document\">" +
                    "<div class=\"modal-content" + "\">" +
                        modalHeader +
                        modalBody +
                        "<div class=\"modal-footer\">" +
                            cancelButtonHtml +
                            "<button type=\"button\" class=\"btn btn-primary ok-button\">" +
                                this.options.okButtonText +
                            "</button>" +
                        "</div>" +
                    "</div>" +
                "</div>" +
            "</div>"
        );

        $(this.options.appendToSelector).append(this.$modalDiv);

        const self = this;
        this.$modalDiv.find(".cancel-button").on("click", function() {

            self.hide();

            if (self.options.cancelCallback !== undefined) {
                self.options.cancelCallback(self);
            }
        });

        this.$modalDiv.find(".ok-button").on("click", function() {

            if (self.options.commentFieldIsRequired === true) {
                const commentIsValid = self.$modalDiv.find("form.fake-comment-form").valid();
                if (commentIsValid === false) {
                    return;
                }
            }

            if (self.options.okCallback !== undefined) {
                self.options.okCallback(self);
            }
        });

        const modalOptions: any = {
            show: false
        };

        if (this.options.actionIsRequired === true) {
            this.$modalDiv.find("button.close").remove();
            modalOptions.backdrop = "static";
        }

        this.$modalDiv.modal(modalOptions);

        if (this.options.deleteOnHide === true) {
            this.$modalDiv.on("hidden.bs.modal", function() {
                self.delete();
            });
        }

        this.$modalDiv.on("shown.bs.modal", function() {

            ($ as any).validator.unobtrusive.parse(self.$modalDiv.find("form.fake-comment-form"));

            if (self.options.didShowCallback !== undefined) {
                self.options.didShowCallback(self);
            }
        });
    }
}
