/*
    Downloaded mobiscroll at https://download.mobiscroll.com/
    Choose Components:
        - Calendar (used in Embla for datetimepicker)

    Choose Themes:
        - Mobiscroll

    Choose Icon Set:
        - None selected
*/

import "../../libs/mobiscroll/js/mobiscroll.jquery.min";
import { MbscCalendarOptions, Calendar as MbscCalendar } from "../../libs/mobiscroll/js/mobiscroll.jquery.min";
import { CleaveOptions } from "cleave.js/options";
import Cleave from "cleave.js/dist/cleave-esm";


/**
 * Datepicker module options
 */
export interface ICalendarModuleOptions {
    /**
     * Date format to when a date is picked
     */
    dateFormat?: string;
    /**
     * Time format to when a date is picked
     */
    timeFormat?: string;
    /**
     * Localization "da" is default, use "en" for english version
     */
    lang?: string;
    /**
     * This event is triggered when a date is selected
     */
    onSetDate?: (event: any, inst: any) => void;
    /**
     * This event is triggered when a Set button is clicked
     */
    onSet?: (event: any, inst: any) => void;
    /**
     * This event is triggered when the datepicker is initialized
     */
    onInit?: (event: any, inst: any) => void;
    /**
     * Min date for datepicker
     */
    min?: Date;
    /**
     * Max date for datepicker
     */
    max?: Date;

    /**
     * The type of the datetimepicker (Date, Time or Date and time). Default value is "Date".
     */
    type?: DatetimePickerTypeEnum;

    /**
     * If true, the scroll wheels are circular.
     */
    circular?: boolean | boolean[];

    /**
     * If true clear button is visible. Default value is "false".
     */
    allowClear?: boolean;

    /**
     * This setting makes it posible for the user to type value into the input field, otherwise input field is readonly. Is allowed by default.
     */
    allowTyping?: AllowTypingOptions | false;

    /**
     * Suppress the warning when the datetimepicker is initialized on multiple elements
     */
    suppressMultipleElementsWarning?: boolean;

    /**
     * Optional: Override additional mobiscroll settings. Does not override datepicker settings defined in ICalendarModuleOptions.
     */
    additionalMbscCalenderOptions?: MbscCalendarOptions;
}

interface IMobiscrollCalendarOptions extends MbscCalendarOptions {
    responsive?: IMobiscrollResponsiveOptions;
}

interface IMobiscrollResponsiveOptions {
    xsmall?: IMobiscrollResponsiveOption;
    small?: IMobiscrollResponsiveOption;
    medium?: IMobiscrollResponsiveOption;
    large?: IMobiscrollResponsiveOption;
    xlarge?: IMobiscrollResponsiveOption;
}

export enum DatetimePickerTypeEnum {
    Time = 1,
    Date = 2,
    DateAndTime = 3
}

interface AllowTypingOptions {

    /**
     * Options: Whether typing in date fields are allowed. Default value is "true".
     */
    enabled?: boolean,

    /**
     * Optional: If true the datetimepicker will when the input is clicked. Default value is "true".
     */
    openOnInputClicked?: boolean

    /**
     * Optional: If true the datetimepicker will open when the icon is clicked. Default value is "true".
     */
    openOnIconClicked?: boolean

    /**
     * Optional: If true the datetimepicker will open when space or enter keys are pressed. Default value is "true".
     */
    openOnKeypress?: boolean

    /**
     * Optional: Override Cleaveoptions for field if custom format is needed, module only supports "/" or "-" as delimeter between day, month and year
     */
    overrideCleaveOptions?: CleaveOptions,
}

interface IMobiscrollResponsiveOption {
    touchUi?: boolean;
    buttons?: string[];
}

export default class DatetimePickerModule {

    private $element: JQuery;

    private mobiscrollInstance: MbscCalendar;

    private readonly moduleInitKey = "calendarmoduleinitialized";

    /**
     * Init the datepicker
     * @param selector Selector or HTML element for the datepicker you want to initialize
     * @param options Options for the datepicker
     */
    constructor(selector: string | HTMLElement, options?: ICalendarModuleOptions) {
        this.initCalendarModule(selector, options);
    }

    /**
     * Get DOM elements, which the datetimepicker was initialized on
     */
    public getDomElements(): JQuery<HTMLElement> {
        return this.$element;
    }

    /**
     * Get the underlying mobiscroll instance, which the datetimepicker uses
     */
    public getMobiscrollInstance(): MbscCalendar {
        return this.mobiscrollInstance;
    }

    /**
     * Set the value of the datetimepicker
     * @param value Value to set as a Date object
     * @param triggerChange If setValue should trigger a DOM change event on the datetimepicker input
     */
    public setValue(value: Date, triggerChange?: boolean): void {

        if (triggerChange === undefined || triggerChange === null) {
            triggerChange = true;
        }

        this.mobiscrollInstance.setVal(value, true, triggerChange);
    }

    /**
     * Gets the value as a Date object
     */
    public getValue(): Date {
        const value = this.mobiscrollInstance.getVal(false);
        return value;
    }

    /**
     * Gets the temporary value as a Date object. The temporary value is the value prior to clicking the Set button.
     */
    public getTempValue(): Date {
        const value = this.mobiscrollInstance.getVal(true);
        return value;
    }

    private initCalendarModule(selector: string | HTMLElement, options: ICalendarModuleOptions): void {

        this.$element = $(selector as any);

        const defaultDatepickerOptions: ICalendarModuleOptions = {
            type: DatetimePickerTypeEnum.Date,
            allowTyping: {
                enabled: true,
                openOnInputClicked: true,
                openOnIconClicked: true,
                openOnKeypress: true
            },
            allowClear: false,
        };

        options = $.extend(true, defaultDatepickerOptions, options);

        const defaultMobiscrollOptions: IMobiscrollCalendarOptions = {
            controls: ["calendar", "time"],
            lang: "da",
            dateFormat: "dd/mm/yy",
            setOnDayTap: false,
            timeFormat: "HH:ii",
            focusTrap: false,
            onSetDate: (event: any, instance: any) => {
                if (event.control === "calendar") {
                    instance.changeTab("time");
                }
            },
            responsive: {
                xsmall: { // xsmall = min-width: 0px
                    touchUi: true,
                    buttons: options.allowClear === true ? ["cancel", "clear", "set"] : ["cancel", "set"]
                },
                xlarge: { // xlarge = min-width: 1200px
                    touchUi: false,
                    buttons: options.allowClear === true ? ["clear"] : []
                }
            },
            circular: true,
            onShow: (event, instance) => {
                const $inputGroupParent = $(instance.element).parent(".input-group");
                $inputGroupParent.addClass("focus");
            }
        };

        let mobiscrollOptions = $.extend(true, defaultMobiscrollOptions, options);

        if (options.type !== undefined && options.type !== null) {
            if (options.type === DatetimePickerTypeEnum.Date) {
                mobiscrollOptions.controls = ["calendar", "date"];
                mobiscrollOptions.setOnDayTap = true;
            } else if (options.type === DatetimePickerTypeEnum.DateAndTime) {
                mobiscrollOptions.controls = ["calendar", "time"];
            } else if (options.type === DatetimePickerTypeEnum.Time) {
                mobiscrollOptions.controls = ["time"];
            }
        }

        const allowTyping = options.allowTyping !== null && options.allowTyping !== undefined && options.allowTyping !== false && options.allowTyping.enabled !== false;

        if(allowTyping === true) {
            mobiscrollOptions.showOnTap = false;
        }

        if (options.additionalMbscCalenderOptions !== undefined) {
            mobiscrollOptions = $.extend(true, {}, mobiscrollOptions, options.additionalMbscCalenderOptions);
        }

        if (this.$element.length > 1 && options.suppressMultipleElementsWarning !== true) {
            console.warn("Datetimepicker initialized on multiple elements, which will lead to the returned instance only affecting the first elements");
        }

        this.$element.each((index, element) => {
            const $element = $(element);

            $element.attr("autocomplete", "off");
            $element.data(this.moduleInitKey, true);

            const $inputGroupText = $element.parent(".input-group").find(".input-group-text");
            $inputGroupText.addClass("clickable");
            if(allowTyping !== true) {
                $inputGroupText.on("click", (e) => {
                    if ($element.is(":disabled") !== true) {
                        $element.trigger("click");
                    }
                });
            }
        });

        this.$element.mobiscroll().calendar(mobiscrollOptions);
        this.mobiscrollInstance = this.$element.mobiscroll("getInst");

        if(allowTyping === true) {

            this.$element.each((index, element) => {
                const $inputElement = $(element);

                const allowTypingOptions = options.allowTyping as AllowTypingOptions;

                let cleaveOptions = allowTypingOptions.overrideCleaveOptions;

                if(cleaveOptions === undefined || cleaveOptions === null)
                {
                    cleaveOptions = this.getCleaveObjectBasedOnOptions(mobiscrollOptions, options.type);
                }

                if(cleaveOptions !== undefined && cleaveOptions !== null) {
                    new Cleave(element, cleaveOptions);
                }
                else {
                    console.warn("Unknown time/date format. Module cannot initialze cleave");
                }

                const showPicker = () => this.mobiscrollInstance.show();

                if(allowTypingOptions.openOnInputClicked !== false) {
                    this.$element.on("click", () => showPicker());
                }

                if(allowTypingOptions.openOnIconClicked !== false) {
                    $inputElement.parent(".input-group").find(".input-group-text").on("click", (e) => {
                        if ($inputElement.is(":disabled") !== true) {
                            showPicker();
                        }
                    });
                }

                if(allowTypingOptions.openOnKeypress !== false) {
                    this.$element.on("keypress", (e) => {
                        if(e.key === "Enter" || e.key === " ") {
                            showPicker();
                            e.preventDefault();
                        }
                    });
                }

            });
        }
    }

    private getCleaveObjectBasedOnOptions(options: IMobiscrollCalendarOptions, calenderType: DatetimePickerTypeEnum): CleaveOptions {

        let cleaveOptions: CleaveOptions = {};

        if(calenderType === DatetimePickerTypeEnum.Date) {
            cleaveOptions = this.getCleaveDateObjectBasedOnFormat(options.dateFormat);
        }
        else if(calenderType === DatetimePickerTypeEnum.DateAndTime) {
            cleaveOptions = this.getCleaveDateTimeYearObjectBasedOnFormat(options.dateFormat, options.timeFormat);
        }
        else if(calenderType === DatetimePickerTypeEnum.Time) {
            cleaveOptions = {
                time: true,
                delimiter: this.getTimeDelimiter(options.timeFormat),
                timePattern: ["h", "m"]
            };
        }

        return cleaveOptions;
    }

    private getCleaveDateObjectBasedOnFormat(format: string): CleaveOptions {

        let cleaveOptions: CleaveOptions = {};

        switch (format) {
            case "dd-mm-y":
            case "dd.mm.y":
            case "dd/mm/y":
                cleaveOptions = {
                    date: true,
                    delimiter: format.charAt(2),
                    datePattern: ["d", "m", "y"]
                };
                break;

            case "mm-dd-y":
            case "mm.dd.y":
            case "mm/dd/y":
                cleaveOptions = {
                    date: true,
                    delimiter: format.charAt(2),
                    datePattern: ["m", "d", "y"]
                };
                break;
            case "dd-mm-yy":
            case "dd.mm.yy":
            case "dd/mm/yy":
                cleaveOptions = {
                    date: true,
                    delimiter: format.charAt(2),
                    datePattern: ["d", "m", "Y"]
                };
                break;

            case "mm-dd-yy":
            case "mm.dd.yy":
            case "mm/dd/yy":
                cleaveOptions = {
                    date: true,
                    delimiter: format.charAt(2),
                    datePattern: ["m", "d", "Y"]
                };
                break;

            case "dd-mm":
            case "dd.mm":
            case "dd/mm":
                cleaveOptions = {
                    date: true,
                    delimiter: format.charAt(2),
                    datePattern: ["d", "m"]
                };
                break;

            case "mm-dd":
            case "mm.dd":
            case "mm/dd":
                cleaveOptions = {
                    date: true,
                    delimiter: format.charAt(2),
                    datePattern: ["m", "d"]
                };
                break;

            default:
                return null;
        }

        return cleaveOptions;
    }

    private getCleaveDateTimeYearObjectBasedOnFormat(dateFormat: string, timeFormat: string): CleaveOptions {

        let cleaveOptions: CleaveOptions = {};
        const timeDelimiter = this.getTimeDelimiter(timeFormat);

        switch (dateFormat) {
            case "dd-mm-yy":
            case "dd.mm.yy":
            case "dd/mm/yy":
                cleaveOptions = {
                    numericOnly: true,
                    delimiters: [dateFormat.charAt(2), dateFormat.charAt(2), " ", timeDelimiter],
                    blocks: [2, 2, 4, 2, 2],
                };
                break;

            case "dd-mm-y":
            case "dd.mm.y":
            case "dd/mm/y":
                cleaveOptions = {
                    numericOnly: true,
                    delimiters: [dateFormat.charAt(2), dateFormat.charAt(2), " ", timeDelimiter],
                    blocks: [2, 2, 2, 2, 2],
                };
                break;

            case "mm-dd-yy":
            case "mm.dd.yy":
            case "mm/dd/yy":
                cleaveOptions = {
                    numericOnly: true,
                    delimiters: [dateFormat.charAt(2), dateFormat.charAt(2), " ", timeDelimiter],
                    blocks: [2, 2, 4, 2, 2],
                };
                break;

            case "mm-dd-y":
            case "mm.dd.y":
            case "mm/dd/y":
                cleaveOptions = {
                    numericOnly: true,
                    delimiters: [dateFormat.charAt(2), dateFormat.charAt(2), " ", timeDelimiter],
                    blocks: [2, 2, 2, 2, 2],
                };
                break;

            case "yy-mm-dd":
            case "yy.mm.dd":
            case "yy/mm/dd":
                cleaveOptions = {
                    numericOnly: true,
                    delimiters: [dateFormat.charAt(2), dateFormat.charAt(2), " ", timeDelimiter],
                    blocks: [4, 2, 2, 2, 2],
                };
                break;

            case "y-mm-dd":
            case "y.mm.dd":
            case "y/mm/dd":
                cleaveOptions = {
                    numericOnly: true,
                    delimiters: [dateFormat.charAt(1), dateFormat.charAt(1), " ", timeDelimiter],
                    blocks: [2, 2, 2, 2, 2],
                };
                break;

            default:
                return null;
        }

        return cleaveOptions;
    }


    private getTimeDelimiter(timeFormat: string): string {
        let timeDelimiter = "";

        switch (timeFormat) {
            case "HH:ii":
            case "hh:ii":
            case "HH.ii":
            case "hh.ii":
            case "HH ii":
            case "hh ii":
                timeDelimiter = timeFormat.charAt(2);
                break;
            default:
                return null;
        }

        return timeDelimiter;
    }
}
