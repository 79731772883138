/**
 * Sticky footer module options
 */
export interface IStickyFooterOptions {

    /**
     * Selector for the container you want to initialize as sticky.
     *
     * Throws an error if empty.
     */
    stickyContainerSelector: string;

    /**
     * Selector for the class that contains the styling for when the sticky footer is sticky.
     *
     * Default value: "sticky-footer-fixed-position"
     */
    fixedPositionClass?: string;

    /**
     * Callback to be called when the sticky footer state changes to sticky.
     */
    didStick?: () => void;

    /**
     * Callback to be called when the sticky footer state changes to unsticky.
     */
    didUnstick?: () => void;

    /**
     * Selector for the element that toggles the left side menu.
     * This ensures that the width of the sticky footer is updated when the menu is hidden and shown.
     */
    leftMenuToggleSelector?: string;

    /**
     * The amount of pixels of the parent container that shoule be visible before showing the sticky footer.
     *
     * E.g. 300: Show the sticky footer when 300 pixels of the parent container is visible.
     *
     * Default value: 300.
     */
    showWhenAmountOfPixelsAreVisible?: number;
}

/**
 * Sticky footer module
 */
export class StickyFooterModule {

    /**
     * Constructor for sticky footer, which initialises the module
     */
    constructor(options: IStickyFooterOptions) {
        this.initModule(options);
    }

    private initModule(options: IStickyFooterOptions) {
        if (options.stickyContainerSelector === undefined || options.stickyContainerSelector == null)
            throw Error("A selector is required!");

        if(options.showWhenAmountOfPixelsAreVisible === undefined || options.showWhenAmountOfPixelsAreVisible === null)
            options.showWhenAmountOfPixelsAreVisible = 300;

        if (options.fixedPositionClass === undefined || options.fixedPositionClass == null)
            options.fixedPositionClass = options.fixedPositionClass = "sticky-footer-fixed-position";

        const $stickyContainers = $(options.stickyContainerSelector);

        $stickyContainers.toArray().forEach((containerElement) => {
            this.bindStickyFooter(containerElement, options);
        });
    }

    private bindStickyFooter = (containerElement: HTMLElement, options: IStickyFooterOptions) => {
        const $stickyContainer = $(containerElement);
        const $placeholderElement = $("<div>");
        $stickyContainer.before($placeholderElement);

        this.updateStickyState($stickyContainer, $placeholderElement, options);

        $(window).on("scroll resize", () => this.updateStickyState($stickyContainer, $placeholderElement, options));

        if(options.leftMenuToggleSelector !== undefined){
            $(options.leftMenuToggleSelector).on("click", () => {
                setTimeout(() => {
                    this.updateStickyState($stickyContainer, $placeholderElement, options);
                }, 260);
            });
        }
    };

    private updateStickyState = ($stickyContainer: JQuery<HTMLElement>, $placeholderElement: JQuery<HTMLElement>, options: IStickyFooterOptions) => {
        if($placeholderElement !== undefined && $placeholderElement.length > 0) {
            $stickyContainer.outerWidth($placeholderElement.width());

            const stickyContainerHeight = $stickyContainer.outerHeight();

            const isFixedBottomOfStickyContainerInViewport = this.getIsFixedBottomOfStickyContainerInViewport($placeholderElement, $stickyContainer);
            const isPlaceholderElementAboveViewportTop = this.getIsPlaceholderElementAboveViewportTop($placeholderElement);
            const isPartOfParentElementVisible = this.getIsPartOfParentElementVisible($placeholderElement, stickyContainerHeight, options.showWhenAmountOfPixelsAreVisible);

            if (isFixedBottomOfStickyContainerInViewport || isPlaceholderElementAboveViewportTop || !isPartOfParentElementVisible) {
                // Unstick
                $stickyContainer.removeClass(options.fixedPositionClass);
                $placeholderElement.css("padding-bottom", "0");

                if (options.didUnstick)
                    options.didUnstick();
            } else if(isPartOfParentElementVisible) {
                // Stick
                $stickyContainer.addClass(options.fixedPositionClass);
                $placeholderElement.css("padding-bottom", stickyContainerHeight + "px");

                if (options.didStick)
                    options.didStick();
            }
        }
    };

    private getIsFixedBottomOfStickyContainerInViewport = ($placeholderElement: JQuery<HTMLElement>, $stickyContainer: JQuery<HTMLElement>) => {
        const fixedBottomOfStickyContainer = $placeholderElement.offset().top + $stickyContainer.outerHeight();
        const viewportBottom = $(window).scrollTop() + $(window).height();
        return fixedBottomOfStickyContainer < viewportBottom;
    };

    private getIsPlaceholderElementAboveViewportTop = ($placeholderElement: JQuery<HTMLElement>) => {
        const placeholderElementBottom = $placeholderElement.offset().top + $placeholderElement.outerHeight();
        const viewportTop = $(window).scrollTop();
        return placeholderElementBottom < viewportTop;
    };

    private getIsPartOfParentElementVisible = ($placeholderElement: JQuery<HTMLElement>, stickyContainerHeight: number, visibleWhenParentContainerNumberOfPixelsIsShown: number) => {
        const $parentElement = $placeholderElement.parent();
        const amountOfParentVisibility = (visibleWhenParentContainerNumberOfPixelsIsShown + stickyContainerHeight) / $parentElement.outerHeight();
        return this.isInViewport($parentElement, amountOfParentVisibility);
    };

    private isInViewport = ($element: JQuery<HTMLElement>, triggerAtAmountOfVisibility?: number) => {
        const y = triggerAtAmountOfVisibility || 1;
        const elementTop = $element.offset().top;
        const elementBottom = elementTop + $element.outerHeight();
        const elementHeight = $element.height();
        const viewportTop = $(window).scrollTop();
        const viewportBottom = viewportTop + $(window).height();
        const deltaTop = Math.min(1, (elementBottom - viewportTop) / elementHeight);
        const deltaBottom = Math.min(1, (viewportBottom - elementTop) / elementHeight);

        return deltaTop * deltaBottom >= y;
    };
}
